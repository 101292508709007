import React, {
  MutableRefObject,
  ReactElement,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
// MUI
import makeStyles from "@mui/styles/makeStyles";
import { CSSProperties } from "@mui/styles";

// Icon
import StarRoundedIcon from "@mui/icons-material/StarRounded";

// UX
import BoxTitle from "./BoxTitle";
// medication
import HeaderBar from "bplus-lib/medication/HeaderBar";
import { FormattedMessage } from "react-intl";

// Types
type DoctorBannerProps = {
  // data
  appointmentType?: "check_up" | "doctor";
  appLoc?: "on-site" | "online";
  headerName?: string;
  titleName?: string;
  hospitalName?: string;
  specialtyName?: string;
  score?: { star: number; review: number };
  experience?: string;
  headerImage?: string;
  // config
  barOnly?: boolean;
  barWhite?: boolean;
  hideDoctor?: boolean;
  // style
  headerClassName?: string;
  contentClassName?: string;
  // callback
  rightIconClick?: () => any;
  // Element
  children: ReactElement;
  bottom?: ReactElement;
};

// Images
const IMAGES = {
  banner: "/images/Appointment/doctor-banner.png",
  pin: "/images/Appointment/pin-location-grey.png",
  follow: "/images/Appointment/follow-grey.png",
  doctor: "/images/Feed/doctor.png",
  video_call: "/images/Appointment/video-call-outline.png",
  neurosurgery: "/images/Appointment/neurosurgery.png",
  hospital: "/images/Appointment/hospital-outline.png",
};

// Styles
const COLORS = {
  bg_gradient: "linear-gradient(158.72deg, #0669EB 0%, #00357A 84.78%)",
  bg_shadow: "linear-gradient(317.15deg, #F9F8F8 -14.59%, #EFEFEF 87.87%)",
  half_white: "rgba(255, 255, 255, 0.6)",
  yellow: "rgba(219, 182, 35, 1)",
  divider: "rgba(255, 255, 255, 0.4)",
  white_02: "rgba(255, 255, 255, 0.2)",
  grey: "rgba(57, 57, 57, 1)",
};

const styles = {
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  ellipsis: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    display: "inline",
  } as CSSProperties,
};

const doctorStyles = {
  gridTemplateColumns: "auto 1fr auto",
  "& > div": {
    height: "100%",
  },
  "& > div:nth-child(1)": {
    width: "70px",
    height: "70px",
  },
};

const descriptionStyles = {
  "& > label": {
    fontSize: "1rem !important",
    fontWeight: "bold",
    color: "white",
    marginBottom: "0.45rem",
    ...styles.ellipsis,
  },
};

const scoreStyles = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  color: COLORS.half_white,
  justifyContent: "flex-start",
  fontSize: ".875rem",
  paddingLeft: "1rem",

  "& .star": {
    display: "flex",
    alignItems: "center",
    backgroundColor: COLORS.white_02,
    width: "fit-content",
    padding: "2px 5px",
    color: "white",
    borderRadius: "8px",
    marginBottom: "0.25rem",
    height: "20px",
    "& svg": {
      color: COLORS.yellow,
      fontSize: "1.1875rem",
    },
  },
} as CSSProperties;

const headerInnerStyles = {
  marginTop: "-50px",
  paddingTop: "53px",
  padding: "15px 1.5rem 43px",
  position: "relative",
  background: COLORS.bg_gradient,
  "& > div:nth-child(1)": {
    ...doctorStyles,
  },
  "& .divider": {
    borderBottom: `1.5px solid ${COLORS.divider}`,
    borderBottomStyle: "dashed",
    margin: "1.15rem 0 0.75rem",
  },
  "& .doctor-profile-detail.check_up > div:nth-child(1)": {
    width: "100px",
    borderRadius: "8px",
    height: "60px",
    border: "none",
  },
} as CSSProperties;

const useStyles = makeStyles((theme) => ({
  screen: {
    background: "white",
    minHeight: "calc(100vh - 50px)",
    width: "100%",
    position: "relative",
    overflowX: "hidden",
    marginTop: "-1px",
    display: "flex",
    flexDirection: "column",
  },
  header_inner: headerInnerStyles,
  description: descriptionStyles,
  score: scoreStyles,
  content: {
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    marginTop: "-16px",
    background: "white",
    width: "100%",
    // height: "17px",
    padding: "16px 16px",
    position: "relative",
    flexGrow: 1,
  },
  label: {
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
    color: "white",
    marginBottom: "-0.45rem",
    "& img.icon": {
      width: "20px",
      marginRight: "0.55rem",
    },
  },
  header_bar: {
    "&.hb-box-bg-gradient": {
      zIndex: 0,
    },
    "& ~ div.bg-gradient": {
      background: "transparent",
      "&:after": {
        backgroundImage: `url(${IMAGES.banner})`,
        height: "calc(100% - -50px)",
        marginTop: "-50px",
        opacity: 1,
      },
    },
  },
}));

// Const
export const APPOINTMENT_TITLE = {
  online: { title: "พบแพทย์ออนไลน์", icon: IMAGES.video_call, style: {} },
  "on-site": {
    title: "ใช้บริการที่โรงพยาบาล ",
    icon: IMAGES.hospital,
    style: { marginTop: "-0.25rem" },
  },
};

const IMAGE_PLACEHOLDER = {
  doctor: IMAGES.doctor,
  check_up: IMAGES.neurosurgery,
};

const DoctorBanner = (props: DoctorBannerProps) => {
  const classes = useStyles();

  const headerRef = useRef() as MutableRefObject<HTMLDivElement>;
  const topBarRef = useRef() as MutableRefObject<HTMLDivElement>;

  // Callback Effect
  const handleLoadHeader = useCallback(() => {
    const header = headerRef.current;
    const container = topBarRef.current.firstElementChild as HTMLDivElement;
    const hAbsolute = topBarRef.current.querySelector(
      ".bg-gradient"
    ) as HTMLDivElement;

    const { height } = header?.getBoundingClientRect() || {};

    if (hAbsolute) {
      hAbsolute.style.height = `${height}px`;
    }

    const bgHeight = height - 50 - 30;

    if (container) {
      container.style.background = `linear-gradient(158.72deg, #0669EB 0%, #00357A calc(84.78% - -${
        bgHeight < 60 ? height - 50 - 10 : bgHeight
      }px))`;
    }
  }, []);

  // Effect
  useEffect(() => {
    handleLoadHeader();
  }, [props.appointmentType, props.barOnly, props.barWhite, props.hideDoctor]);

  // Memo
  const showAppointmentLocation = useMemo(() => {
    return !!props.appLoc && props.appointmentType !== "check_up";
  }, [props.appLoc, props.appointmentType]);

  const showScore = useMemo(() => {
    return !props.appLoc && props.appointmentType !== "check_up";
  }, [props.appLoc, props.appointmentType]);

  const showExperience = useMemo(() => {
    return showScore;
  }, [showScore]);

  const headerConfig = useMemo(() => {
    return props.rightIconClick
      ? { hiddenRight: false, hiddenLeft: true, buttonLeftback: false }
      : { hiddenRight: true, hiddenLeft: true, buttonLeftback: true };
  }, [props.rightIconClick]);

  return (
    <>
      <HeaderBar
        ref={topBarRef}
        className={classes.header_bar}
        // data
        setTitle={props.headerName}
        bgGradient={true}
        // style
        headerSx={{
          background: props.barWhite ? "white !important" : "transparent",
          "& .left.icon": {
            color: props.barWhite ? `${COLORS.grey} !important` : "white",
            marginLeft: "0.35rem",
          },
        }}
        titleStyle={{
          fontWeight: "bold",
          fontSize: "1.1rem",
          color: props.barWhite ? COLORS.grey : "",
        }}
        // config
        hiddenRight={headerConfig.hiddenRight}
        hiddenLeft={headerConfig.hiddenLeft}
        buttonLeftback={headerConfig.buttonLeftback}
        // callback
        rightIconClick={props.rightIconClick}
      >
        {/* <div className={classes.header_absolute}></div> */}
      </HeaderBar>

      <div className={classes.screen}>
        {!props.barOnly && (
          <div
            ref={headerRef}
            className={`${classes.header_inner}  ${
              props.headerClassName || ""
            }`}
          >
            {!props.hideDoctor && (
              <>
                <div
                  className={`doctor-profile-detail ${props.appointmentType}`}
                >
                  <div>
                    <img
                      src={
                        props.headerImage ||
                        IMAGE_PLACEHOLDER[props.appointmentType || "doctor"]
                      }
                      onError={(e: any) => {
                        e.target.src =
                          IMAGE_PLACEHOLDER[props.appointmentType || "doctor"];
                      }}
                    />
                  </div>
                  <div className={classes.description}>
                    <label
                      style={{
                        marginBottom:
                          props.appointmentType !== "check_up" ? "" : 0,
                      }}
                    >
                      {props.titleName}
                    </label>
                    {showAppointmentLocation && (
                      <BoxTitle
                        type="label"
                        mini={true}
                        labelStyle={{ color: "white", marginTop: "-0.25rem" }}
                      >
                        <label>{props.specialtyName}</label>
                      </BoxTitle>
                    )}

                    <BoxTitle
                      type="label"
                      mini={true}
                      labelStyle={{
                        marginBottom:
                          props.appLoc || props.appointmentType === "check_up"
                            ? 0
                            : "",
                        color: COLORS.half_white,
                      }}
                    >
                      <img className="icon" src={IMAGES.pin}></img>
                      <label>{props.hospitalName || "-"}</label>
                    </BoxTitle>

                    {showExperience && (
                      <BoxTitle
                        type="label"
                        mini={true}
                        labelStyle={{
                          marginBottom: 0,
                          color: COLORS.half_white,
                        }}
                      >
                        <img className="icon" src={IMAGES.follow}></img>
                        <label>
                          <FormattedMessage id="bplusClinicKey1251" />:{" "}
                          {props.experience
                            ? Number(props.experience).toLocaleString("en-US", {
                                style: "decimal",
                                maximumFractionDigits: 0,
                                minimumFractionDigits: 0,
                              })
                            : "-"}{" "}
                          <FormattedMessage id="bplusClinicKey590" />
                        </label>
                      </BoxTitle>
                    )}
                  </div>
                  {showScore && (
                    <div>
                      <div className={classes.score}>
                        <div className="star">
                          <StarRoundedIcon />
                          <label>{props.score?.star?.toFixed(1) || "-"}</label>
                        </div>
                        <label>
                          {props.score?.review || "-"}{" "}
                          <FormattedMessage id="bplusClinicKey1250" />
                        </label>
                      </div>
                    </div>
                  )}
                </div>

                {showAppointmentLocation && props.appLoc && (
                  <>
                    <div className="divider"></div>
                    <div className={classes.label}>
                      <img
                        className="icon"
                        src={APPOINTMENT_TITLE[props.appLoc].icon}
                        style={APPOINTMENT_TITLE[props.appLoc].style}
                      ></img>
                      <label>
                        {APPOINTMENT_TITLE[props.appLoc].title ===
                        "พบแพทย์ออนไลน์" ? (
                          <FormattedMessage id="bplusClinicKey954" />
                        ) : (
                          <FormattedMessage id="bplusClinicKey1132" />
                        )}{" "}
                      </label>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}
        <div
          className={`${classes.content}  ${props.contentClassName || ""}`}
          style={{ marginTop: props.barOnly ? 0 : "" }}
        >
          {props.children}
        </div>
        <div>{props.bottom}</div>
      </div>
    </>
  );
};

export default React.memo(DoctorBanner);
