import React, { useState, useMemo } from "react";
// MUI
import makeStyles from "@mui/styles/makeStyles";

import { useHistory } from "react-router";
import moment from "moment";

// UX
import CardAppointment from "./CardAppointment";
import BottomSheetListItem from "./BottomSheetListItem";
import CardDoctorSkeleton from "./CardDoctorSkeleton";
import ItemNotFound from "./ItemNotFound";
// telephar
import DialogConfirm from "bplus-lib/telephar/DialogConfirm";
import PaymentAlertMessage from "bplus-lib/telephar/PaymentAlertMessage";
// register
import DialogReadMore from "bplus-lib/register/DialogReadMore";

// Icon
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

// Interface
import { Event, State } from "../MobSmartAppointmentInterface";

// Const.
import { APPOINTMENT_ACTION_OPTIONS, APPOINTMENT_ACTION_OPTIONS_EN, URLS } from "./Constants";
import { BE_FORMAT_DATE } from "bplus-lib/appointment/AppCalendar";
import { FormattedMessage, useIntl } from "react-intl";

// Types
type CardAppointmentListProps = {
  onEvent: (e: Event) => any;
  loading?: boolean;
  items?: { [key: string]: any; type: "doctor" | "check_up"; id: number }[];
  allPaidInvoiceList?: any;
  refundRequestsList?: any;
  noResultsMessage?: {
    title?: string;
    desc?: string;
  };
  language?: "en-US" | "th-TH";
}

// Images
const IMAGES = {
  notfound: "/images/Appointment/verified-appointment.png",
  cancel_hn: "/images/telephar/cancel-hn.png",
};

// Styles
const COLORS = {
  grey: "rgba(57, 57, 57, 1)",
  light_grey: "rgba(121, 120, 120, 1)",
  border: "rgba(233, 233, 233, 1)",
};

const listItemStyles = {
  "& div[class*=screen]": {
    paddingTop: 0,
    "& > .MuiCard-root": {
      border: "none",
      borderRadius: 0,
      borderBottom: `1px solid ${COLORS.border}`,
      marginBottom: 0,
      "& .MuiCardContent-root ": {
        color: COLORS.grey,
        fontWeight: "normal",
        padding: "16px 8px",
      },
      "& svg": {
        fontSize: "1rem",
      },
    },
    "& > .MuiCard-root:last-child": {
      border: "none",
    },
  },
};

const useStyles = makeStyles(() => ({
  list_item: listItemStyles,
}));

const CardAppointmentList = (props: CardAppointmentListProps) => {
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl()

  const [modMore, setModMore] = useState<Record<string, any> | null>(null);
  const [openModReadMore, setOpenModReadMore] = useState<boolean>(false);
  const [openModUneditable, setOpenModUneditable] = useState<boolean>(false);
  const [openNote,setOpenNote] = useState<string>("");

  const items = useMemo(() => {
    const skeletons = Array(4)
      .fill("")
      .map((_, index) => ({ id: index } as any));

    return props.loading ? skeletons : props.items || [];
  }, [props.loading, props.items]);

  const handleSelectAppointmentAction = (data: any) => {
    const appointmentLocation = modMore?.is_telemed ? "online" : "on-site";
    const dateFormat = moment(modMore?.date).format(BE_FORMAT_DATE);
    const date = moment(`${dateFormat} ${modMore?.start_time}`);
    const requestType = modMore?.realtime ? "REALTIME" : "CRM";
    const doctor = modMore?.doctor_details || {};

    const diff = moment().diff(date, "minutes");

    if (data.id === 1) {
      if (diff > -30) {
        setOpenModUneditable(true);
      } else {
        props.onEvent({
          message: "HandleHistoryPushState",
          params: {
            pathname: URLS.SELECT_DATETIME,
            history,
            state: {
              editAppointment: modMore,
              centerGroup: {
                appointment_type: modMore?.type,
              },
              appointmentLocation,
              requestType,
              doctor: {
                ...doctor,
                specialties: doctor.specialties?.join(", "),
              },
              hospital: {
                code: modMore?.hospital_code,
                name: modMore?.hospital_name,
              },
              division: {
                id: modMore?.division,
                name: modMore?.division_name,
                is_telemed: modMore?.is_telemed,
                price: null,
              },
              reason: {
                first_consultation: ["พบแพทย์ครั้งแรก","First Consultation"].includes(modMore?.med_service_name)
              }
            },
          },
        });
      }
    } else if (data.id === 2) {
      const invoice = handleCheckInVoice(modMore);
      props.onEvent({
        message: "HandleHistoryPushState",
        params: {
          pathname: URLS.CANCEL_APPOINTMENT,
          history,
          state: {
            appointmentLocation,
            requestType,
            isLessTime: diff > -30,
            cancelAppointment: modMore,
            invoice: invoice || {},
            hospital: {
              code: modMore?.hospital_code,
              name: modMore?.hospital_name,
            },
          },
          storedState: {},
        },
      });
    }
  };

  const handleCloseModMore = () => {
    setModMore(null);
  };

  const handleOpenModReadMore = () => {
    setOpenModReadMore(true);
  };

  const handleCloseModReadMore = () => {
    setOpenModReadMore(false);
  };

  const handleCloseModUneditable = () => {
    setOpenModUneditable(false);
  };

  const handleCheckInVoice = (item: any | Record<string, any>) => {

    if (item.is_telemed && item) {
      return (
        (props.allPaidInvoiceList || []).filter(
          (items: any) => item.encounter_number === items.number
        )?.[0] || {}
      );
    } else {
      return {};
    }
  };

  const handleRefundRequest = (item: any | Record<string, any>) => {
    if (item.is_telemed) {
      return (
        (
          (props?.refundRequestsList || []).filter(
            (items: any) => items?.book_id === item?.id
          ) || []
        ).length > 0
      );
    } else {
      return false;
    }
  };

  return (
    <>
      {items.length ? (
        items.map((item) =>
          props.loading ? (
            <CardDoctorSkeleton key={"card-" + item.id} />
          ) : (
            <CardAppointment
              language={props.language}
              key={"card-" + item.id}
              type={item.type}
              data={item}
              invoice={handleCheckInVoice(item)}
              isRefundRequest={handleRefundRequest(item)}
              // callback
              onClickMore={setModMore}
              onClickNote={setOpenNote}
            />
          )
        )
      ) : (
        <ItemNotFound
          img={IMAGES.notfound}
          imgSize="12.5rem"
          title={
            props.noResultsMessage
              ? props.noResultsMessage.title
              : intl.formatMessage({ id: "bplusClinicKey1219" })
          }
          subTitle={
            props.noResultsMessage
              ? props.noResultsMessage.desc
              : intl.formatMessage({ id: "bplusClinicKey1220" })
          }
        />
      )}

      <BottomSheetListItem
        open={!!modMore}
        className={classes.list_item}
        title={intl.formatMessage({ id: "bplusClinicKey1221" })}
        options={props.language === "th-TH" ? APPOINTMENT_ACTION_OPTIONS : APPOINTMENT_ACTION_OPTIONS_EN}
        rightIcon={<ArrowForwardIosRoundedIcon />}
        // callnack
        onChange={handleCloseModMore}
        onSelect={handleSelectAppointmentAction}
      />

      <DialogConfirm
        open={openModUneditable}
        title={intl.formatMessage({id: "bplusClinicKey1338"})}
        approveText={intl.formatMessage({id: "bplusClinicKey116"})}
        img={IMAGES.cancel_hn}
        imgSize="12.5rem"
        // config
        hideDeny={true}
        onApprove={handleCloseModUneditable}
      >
        <>
          <div style={{ color: COLORS.light_grey }}>
            <FormattedMessage id="bplusClinicKey1339" />
          </div>
          <PaymentAlertMessage
            type="info-red"
            style={{ marginTop: "1rem", padding: "13px 16px" }}
          >
            <>
              <FormattedMessage id="bplusClinicKey1340" />
              {/* <div
                aria-hidden="true"
                style={{ textDecorationLine: "underline" }}
                onClick={handleOpenModReadMore}
              >
                นโยบายการเปลี่ยนแปลงนัดหมาย
              </div> */}
            </>
          </PaymentAlertMessage>
        </>
      </DialogConfirm>

      <DialogReadMore
        open={openModReadMore}
        title="นโยบายการเปลี่ยนแปลงนัดหมาย"
        onChange={handleCloseModReadMore}
      >
        <div>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum. It is a long established fact that
          a reader will be distracted by the readable content of a page when
          looking at its layout. The point of using Lorem Ipsum is that it has a
          more-or-less normal distribution of letters, as opposed to using
          'Content here, content here', making it look like readable English.
          Many desktop publishing packages and web page editors now use Lorem
          Ipsum as their default model text, and a search for 'lorem ipsum' will
          uncover many web sites still in their infancy. Various versions have
          evolved over the years, sometimes by accident, sometimes on purpose
          (injected humour and the like). Contrary to popular belief, Lorem
          Ipsum is not simply random text. It has roots in a piece of classical
          Latin literature from 45 BC, making it over 2000 years old. Richard
          McClintock, a Latin professor at Hampden-
        </div>
      </DialogReadMore>

      <DialogConfirm
          open={!!openNote}
          title={"คำแนะนำในการปฏิบัติตัว"}
          description={openNote}
          approveText='ปิด'
          // config
          hideImage={true}
          hideDeny={true}
          onApprove={() => setOpenNote("")}
        />
        
    </>
  );
};

export default React.memo(CardAppointmentList);
