import React, { useCallback, useEffect, useState } from "react";
// MUI

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import Radio from "@mui/material/Radio";
import Badge from "@mui/material/Badge";
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";

// UX
import DialogReadMore from "bplus-lib/register/DialogReadMore";
import { FormattedMessage, useIntl } from "react-intl";

// Types
type BottomSheetSelectHospitalProps = {
  // data
  open: boolean;
  hospitalList?: any[];
  selectedHospital?: Record<string, any> | null;
  // callback
  onChange: (state: boolean) => any;
  onSelect?: (data: any) => any;
};

// Styles
const COLORS = {
  half_grey: "rgba(196, 196, 196, 0.5)",
  light_grey: "rgba(196, 196, 196, 1)",
  grey: "rgba(57, 57, 57, 1)",
};

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "none !important",
    borderBottom: `1px solid ${COLORS.half_grey}`,
    backgroundColor: "transparent !important",
    "& .MuiCardContent-root": {
      display: "grid",
      gridTemplateColumns: "auto 1fr",
      alignItems: "center",
      padding: "20px 21px",
      fontWeight: "bold",
      color: COLORS.grey,
      width: "100%",
      "& div:nth-child(2)": {
        display: "flex",
        alignItems: "center",
      },
    },
  },
  badge: {
    "& .MuiBadge-badge": {
      fontSize: "1rem",
      minWidth: "25px",
      minHeight: "25px",
      borderRadius: "500rem",
    },
  },
}));

const BottomSheetSelectHospital = (props: BottomSheetSelectHospitalProps) => {
  const [selectedHospital, setSelectedHospital] = useState<Record<
    string,
    any
  > | null>(null);
  const intl = useIntl()

  // Effect
  useEffect(() => {
    setSelectedHospital(props.selectedHospital || null);
  }, [props.selectedHospital]);

  // Handler
  const handleConfirm = () => {
    props.onSelect?.(selectedHospital);

    props.onChange?.(false);
  };

  const handleChange = (state: boolean) => {
    props.onChange?.(state);

    setSelectedHospital(props.selectedHospital || null);
  };

  return (
    <DialogReadMore
      open={props.open}
      title={intl.formatMessage({ id: "bplusClinicKey1226" })}
      centerTitle={true}
      // callback
      onChange={handleChange}
    >
      <div style={{ maxHeight: "75vh", overflow: "auto", margin: "0 -20px" }}>
        {props.hospitalList?.map((item) => (
          <CardItem
            key={"item" + item.code}
            data={item}
            checked={selectedHospital?.code === item.code}
            // callback
            onSelect={setSelectedHospital}
          />
        ))}

        <div className="button-submit-bottom">
          <MuiButton
            variant="contained"
            color="primary"
            style={{
              marginTop: "3rem",
            }}
            onClick={handleConfirm}
          >
            <FormattedMessage id={"common.confirm"}/>
          </MuiButton>
        </div>
      </div>
    </DialogReadMore>
  );
};

/* ------------------------------------------------------ */

/*                        CardItem                        */

/* ------------------------------------------------------ */

// Types
type CardItemType = {
  data: any;
  checked?: boolean;
  // callback
  onSelect?: (data: any) => any;
};

const CardItem = (props: CardItemType) => {
  const classes = useStyles();

  const getBadgeRight = useCallback((count: number) => {
    if (count < 10) {
      return 9;
    } else if (count < 100) {
      return 12;
    } else {
      return 14;
    }
  }, []);

  const handleClick = () => {
    props.onSelect?.(props.data);
  };

  return (
    <Card
      key={props.data.name}
      className={`${classes.card}`}
      onClick={handleClick}
    >
      <CardActionArea>
        <CardContent>
          <div>
            <Radio
              className={`smart-radio-button`}
              checked={props.checked}
              name="radio-buttons"
              style={{ padding: "9px" }}
            />
          </div>
          <div style={{ margin: "0.15rem 0 0 0.25rem" }}>
            {props.data.name}
            <div style={{ flex: 1 }}></div>
            <Badge
              className={classes.badge}
              badgeContent={props.data.count}
              color="error"
              sx={{
                "& .MuiBadge-badge": {
                  right: getBadgeRight(props.data.count),
                },
              }}
            ></Badge>
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default React.memo(BottomSheetSelectHospital);
