import React, { useEffect, useState, useMemo } from "react";
import { Input, InputProps } from "semantic-ui-react";
// MUI
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import Radio from "@mui/material/Radio";
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
// Icon
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";

// UX
import DialogReadMore from "bplus-lib/register/DialogReadMore";
import { FormattedMessage, useIntl } from "react-intl";

// Types
type BottomSheetSelectBoxProps = {
  // data
  open: boolean;
  title: string;
  selected?: Record<string, any> | null;
  centered?: boolean;
  options: { id: number; name: string; other?: boolean }[];
  // config
  confirmSelect?: boolean;
  clearOnChecked?: boolean; //* ล้างตัวเลือกเมื่อกดซ้ำ
  // callback
  onChange: (state: boolean) => any;
  onSelect?: (data: any) => any;
};

// Styles
const COLORS = {
  half_grey: "rgba(196, 196, 196, 0.5)",
  grey: "rgba(57, 57, 57, 1)",
  primary: "var(--blue-bdms-color)",
};

const useStyles = makeStyles((theme) => ({
  screen: {
    maxHeight: "75vh",
    overflow: "auto",
    "& > div .MuiCard-root:last-child:after": {
      display: "none !important",
    },
  },
  card: {
    boxShadow: "none !important",
    // borderBottom: `1px solid ${COLORS.half_grey}`,
    backgroundColor: "transparent !important",
    "& .MuiCardContent-root": {
      padding: "22.45px",
      paddingLeft: "16px",
      color: COLORS.grey,
      width: "100%",
      "& div:nth-child(2)": {
        display: "flex",
        alignItems: "center",
      },
      "&.checked": {
        padding: "16px 21px 16px 14.5px",
      },
      "& input": {
        borderRadius: "8px",
        padding: "0.9rem 1rem",
        margin: "0.5rem 0.15rem 0",
      },
      "& .container": {
        display: "grid",
        gridTemplateColumns: "94.5% 1fr",
        alignItems: "center",
      },
    },
    "&.centered .MuiCardContent-root": {
      padding: "22.45px 1.5px",
      "&.checked": {
        padding: "16px 8px 16px 0px",
      },
    },
    "&:after": {
      content: "' '",
      position: "absolute",
      borderBottom: `1px solid ${COLORS.half_grey}`,
      width: "calc(100% - 32px)",
      left: "16px",
    },
  },
}));

const BottomSheetSelectBox = (props: BottomSheetSelectBoxProps) => {
  const classes = useStyles();
  const intl = useIntl()

  const [selected, setSelected] = useState<Record<string, any> | null>(null);

  // Effect
  useEffect(() => {
    if (props.selected) {
      setSelected(props.selected);
    }
  }, [props.selected]);

  // Effect
  useEffect(() => {
    if (props.open && !props.selected) {
      setSelected(null);
    }
  }, [props.open, props.selected]);

  // Memo
  const allowConfirm = useMemo(() => {
    return selected?.other ? selected.other_desc : !!selected;
  }, [selected]);

  // Handler
  const handleChange = (state: boolean) => {
    if (!props.confirmSelect) {
      props.onSelect?.(selected);
    }

    props.onChange?.(state);
  };

  const handleConfirm = () => {
    props.onSelect?.(selected);
  };

  const handleSelect = (data: any) => {
    if (props.clearOnChecked) {
      setSelected(data?.id === selected?.id ? null : data);
    } else {
      setSelected(data);
    }
  };

  return (
    <DialogReadMore
      open={props.open}
      title={props.title}
      centerTitle={true}
      // config
      centered={props.centered}
      // callback
      onChange={handleChange}
    >
      <div
        className={classes.screen}
        style={{
          margin: props.centered ? "0 -5px" : "0 -20px",
        }}
      >
        <div>
          {props.options.map((item) => (
            <CardItem
              key={"item" + item.id}
              data={item}
              otherDesc={selected?.other_desc}
              checked={selected?.id === item.id}
              centered={props.centered}
              confirmSelect={props.confirmSelect}
              // callback
              onSelect={handleSelect}
            />
          ))}
        </div>

        {props.confirmSelect && (
          <div className="button-submit-bottom">
            <MuiButton
              variant="contained"
              color="primary"
              disabled={!allowConfirm}
              style={{
                marginTop: "2rem",
              }}
              onClick={handleConfirm}
            >
              <FormattedMessage id="common.confirm"/>
            </MuiButton>
          </div>
        )}
      </div>
    </DialogReadMore>
  );
};

/* ------------------------------------------------------ */

/*                        CardItem                        */

/* ------------------------------------------------------ */

// Types
type CardItemType = {
  data: any;
  checked?: boolean;
  centered?: boolean;
  confirmSelect?: boolean;
  otherDesc?: string;
  // callback
  onSelect?: (data: any) => any;
};

const CardItem = (props: CardItemType) => {
  const classes = useStyles();
  const intl = useIntl()
  // Handler
  const handleClick = () => {
    props.onSelect?.(
      props.checked
        ? { ...props.data, other_desc: props.otherDesc }
        : props.data
    );
  };

  const handleChangeInput = (e: any, data: InputProps) => {
    props.onSelect?.({ ...props.data, other_desc: data.value });
  };

  return (
    <Card
      key={props.data.name}
      className={`${classes.card} ${props.centered ? "centered" : ""}`}
      onClick={handleClick}
    >
      <CardActionArea>
        <CardContent className={props.checked ? "checked" : ""}>
          <div className="container">
            <div
              style={{
                margin: `0.15rem 0 0 ${props.checked ? "0.35rem" : "0.25rem"}`,
                color: props.checked ? COLORS.primary : "",
              }}
            >
              {props.data.name}
            </div>
            <div>
              {(props.checked || props.confirmSelect) && (
                <div
                  className={
                    props.confirmSelect ? "" : "smart-radio-button--check"
                  }
                >
                  <Radio
                    className={`smart-radio-button`}
                    checked={props.checked}
                    name="radio-buttons"
                    style={
                      !props.checked && props.confirmSelect
                        ? { padding: "0 0 0 9px" }
                        : { padding: "9px" }
                    }
                  />
                  {!props.confirmSelect && (
                    <CheckRoundedIcon
                      className={props.checked ? "checked" : ""}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
          {props.data.other && props.checked && (
            <Input
              value={props.otherDesc || ""}
              placeholder={intl.formatMessage({id:"bplusClinicKey1316"})}
              fluid={true}
              onClick={(e: any) => e.stopPropagation()}
              onFocus={(e: any) => e.stopPropagation()}
              onChange={handleChangeInput}
            />
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default React.memo(BottomSheetSelectBox);
