import React, { SyntheticEvent, useMemo } from "react";
// MUI
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import CircularProgress from "@mui/material/CircularProgress";
import { CSSProperties } from "@mui/styles";

// Interface
import { DoctorAppointmentActionType } from "./Types";
import { State } from "../MobSmartAppointmentInterface";
import { FormattedMessage } from "react-intl";

// Types
type ButtonAppointmentTypesProps = {
  id?: string | number;
  // style
  style?: CSSProperties;
  // config
  separator?: boolean;
  disabledOnline?: boolean;
  disabledOnSite?: boolean;
  // callback
  onButtonAction?: (
    action: DoctorAppointmentActionType,
    loading: string
  ) => any;
} & Pick<State, "loadingStatus">;

// Images
const IMAGES = {
  video_call: "/images/Appointment/video-call-outline.png",
  hospital: "/images/Appointment/hospital-outline.png",
  video_call_blue: "/images/Appointment/video-call-outline-blue.png",
  hospital_blue: "/images/Appointment/hospital-outline-light-blue.png",
};

// Styles
const COLORS = {
  light_grey: "rgba(121, 120, 120, 1)",
  primary: "var(--blue-bdms-color)",
  dodger_blue: "rgba(0, 114, 188, 1)",
  blue: "rgba(0, 53, 122, 1)",
  bg_bottom: "rgba(250, 250, 250, 1)",
  light_grey_07: "rgba(233, 233, 233, 0.7)",
  light_blue: "rgba(1, 71, 163, 0.4)",
  bg_light_blue: "#d6e1f1",
};

const styles = {
  ellipsis: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    display: "inline-block",
  } as CSSProperties,
};

const useStyles = makeStyles(() => ({
  screen: {
    display: "flex",
    padding: "0 14px 16px",
    "& > div": {
      width: "50%",
      "& img": {
        width: "16px",
        height: "16px",
        marginRight: "0.25rem",
      },
      "& .button-submit-bottom": {
        padding: 0,
        marginTop: "0.45rem",
        "& button": {
          fontSize: ".875rem",
          padding: "0 5px",
          height: "36px",
          width: "100%",
          borderRadius: "8px",
        },
        "&.dodger_blue button": {
          background: COLORS.dodger_blue,
        },
      },
      "& > label": {
        fontSize: ".875rem",
        color: COLORS.light_grey,
        "& span": {
          fontWeight: "bold",
          marginLeft: "0.25rem",
        },
      },
    },
    "& > div:nth-child(1)": {
      paddingRight: "6px",
    },
    "& > div:nth-child(2)": {
      paddingLeft: "6px",
    },
    "&.separator": {
      backgroundColor: COLORS.bg_bottom,
      paddingTop: "0.45rem",
      margin: "0 -14px -16px",
      "& > div:nth-child(1)": {
        paddingRight: "12px",
        borderRight: `1px solid ${COLORS.light_grey_07}`,
      },
      "& > div:nth-child(2)": {
        paddingLeft: "12px",
      },
    },
  },
}));

const BUTTON_ACTIONS = {
  onsite: "_ONSITE",
  online: "_ONLINE",
};

const BUTTON_APPOINTMENT_TYPES = "ButtonAppointmentTypes";
const ACTION_ONLINE = `${BUTTON_APPOINTMENT_TYPES}${BUTTON_ACTIONS.online}`;
const ACTION_ONSITE = `${BUTTON_APPOINTMENT_TYPES}${BUTTON_ACTIONS.onsite}`;

const ButtonAppointmentTypes = (props: ButtonAppointmentTypesProps) => {
  const classes = useStyles();

  // Memo
  const actionOnline = useMemo(() => {
    return props.id ? `${ACTION_ONLINE}_${props.id}` : ACTION_ONLINE;
  }, [props.id]);

  const actionOnsite = useMemo(() => {
    return props.id ? `${ACTION_ONSITE}_${props.id}` : ACTION_ONSITE;
  }, [props.id]);

  const disabledOnSite = useMemo(() => {
    return props.disabledOnSite || props.loadingStatus?.[actionOnsite];
  }, [props.loadingStatus, props.disabledOnSite, actionOnsite]);

  const disabledOnline = useMemo(() => {
    return props.disabledOnline || props.loadingStatus?.[actionOnline];
  }, [props.loadingStatus, props.disabledOnline, actionOnline]);

  // Handler
  const handleClick =
    (action: DoctorAppointmentActionType) => (e: SyntheticEvent) => {
      e.stopPropagation();
      e.preventDefault();

      props.onButtonAction?.(
        action,
        action === "online" ? actionOnline : actionOnsite
      );
    };

  return (
    <div
      className={`${classes.screen} ${props.separator ? "separator" : ""}`}
      style={props.style}
    >
      <div>
        <label>{/* พร้อมบริการ:<span>30 นาที</span> */}</label>
        <div
          className={`button-submit-bottom ${
            disabledOnSite ? "" : "dodger_blue"
          }`}
        >
          <MuiButton
            variant="contained"
            color="primary"
            disabled={disabledOnSite}
            onClick={handleClick("on-site")}
          >
            <img
              src={disabledOnSite ? IMAGES.hospital_blue : IMAGES.hospital}
            />
            <label style={{ ...styles.ellipsis, marginBottom: "-2px" }}>
            <FormattedMessage id="bplusClinicKey1132"/>
            </label>
            {props.loadingStatus?.[actionOnsite] && (
              <CircularProgress
                style={{
                  marginLeft: "0.25rem",
                }}
                size={13}
              />
            )}
          </MuiButton>
        </div>
      </div>{" "}
      <div>
        <label>{/* พร้อมบริการ:<span>วันพรุ่งนี้</span> */}</label>
        <div className="button-submit-bottom">
          <MuiButton
            variant="contained"
            color="primary"
            // disabled={disabledOnline}
            onClick={handleClick("online")}
            style={{...(disabledOnline && {
              color: COLORS.light_blue,
              backgroundColor: COLORS.bg_light_blue,
            })}}
          >
            <img
              src={disabledOnline ? IMAGES.video_call_blue : IMAGES.video_call}
            />
            <label style={{ ...styles.ellipsis }}><FormattedMessage id="bplusClinicKey954"/></label>
            {props.loadingStatus?.[actionOnline] && (
              <CircularProgress
                style={{
                  marginLeft: "0.25rem",
                }}
                size={13}
              />
            )}
          </MuiButton>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ButtonAppointmentTypes);
