import React from "react";
// MUI
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import makeStyles from "@mui/styles/makeStyles";
import { CSSProperties } from "@mui/styles";

// Icon
import StarRoundedIcon from "@mui/icons-material/StarRounded";

// UX
import ButtonAppointmentTypes from "./ButtonAppointmentTypes";
import BoxTitle from "./BoxTitle";
import { CardDoctorListProps } from "./CardDoctorList";

// Interface
import { AppointmentDoctorType, DoctorAppointmentActionType } from "./Types";
import { FormattedMessage } from "react-intl";

// Types
type CardDoctorContentProps = {
  data: AppointmentDoctorType;
  // callback
} & Pick<CardDoctorListProps, "loadingStatus" | "onSelect" | "onButtonAction">;

// Images
const IMAGES = {
  doctor: "/images/Feed/doctor.png",
  pin: "/images/payment/pin-location.png",
  follow: "/images/Appointment/follow-icon.png",
  hospital: "/images/Appointment/hospital-outline.png",
};

// Styles
const COLORS = {
  shadow: "1px 8px 18px 0px rgba(0, 102, 255, 0.1)",
  bg_shadow: "linear-gradient(317.15deg, #F9F8F8 -14.59%, #EFEFEF 87.87%)",
  light_yellow: "rgba(255, 244, 201, 1)",
  yellow: "rgba(219, 182, 35, 1)",
  grey: "rgba(57, 57, 57, 1)",
  light_grey: "rgba(121, 120, 120, 1)",
  primary: "var(--blue-bdms-color)",
  dodger_blue: "rgba(0, 114, 188, 1)",
  blue: "rgba(0, 53, 122, 1)",
  bg_bottom: "rgba(250, 250, 250, 1)",
  light_grey_07: "rgba(233, 233, 233, 0.7)",
};

const styles = {
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  ellipsis: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    display: "inline-block",
  } as CSSProperties,
};

const scoreStyles = {
  display: "flex",
  alignItems: "center",
  marginTop: "0.45rem",
  color: COLORS.light_grey,
  "& .star": {
    display: "flex",
    alignItems: "center",
    backgroundColor: COLORS.light_yellow,
    width: "fit-content",
    padding: "2px 5px",
    fontSize: "1rem",
    color: COLORS.yellow,
    borderRadius: "4px",
    "& svg": {
      fontSize: "1.1875rem",
    },
  },
  "& .dot": {
    width: "2px",
    height: "2px",
    backgroundColor: COLORS.grey,
    margin: "0 5px",
  },
};

export const descriptionStyles = {
  marginTop: "0.85rem",
  display: "grid",
  gridTemplateColumns: "1fr auto",
  alignItems: "center",
  "& .price": {
    color: COLORS.light_grey,
    display: "grid",
    marginTop: "-8px",
    fontSize: ".875rem",
    "& > label:nth-child(1)": {
      color: COLORS.primary,
      fontSize: "1.25rem",
      fontWeight: "bold",
      marginBottom: "5px",
    },
  },
};

export const cardStyles = {
  "&.MuiPaper-root ": {
    boxShadow: COLORS.shadow,
  },
  "& .MuiCardContent-root": {
    width: "100%",
    padding: "16px 14px",
  },
  marginBottom: "12px",
};

const useStyles = makeStyles(() => ({
  card: cardStyles,
  title: {
    fontWeight: "bold",
    marginBottom: "0.55rem",
  },
  score: scoreStyles,
  description: descriptionStyles,
  bottom: { display: "flex", "& > div": { width: "50%" } },
}));

const CardDoctorContent = (props: CardDoctorContentProps) => {
  const classes = useStyles();

  // Handler
  const handleSelect = () => {
    props.onSelect?.(props.data);
  };

  const handleButtonAction = (
    action: DoctorAppointmentActionType,
    loading: string
  ) => {
    if (!props.data.is_telemed && action === "online") {
    console.log("disabledOnline :", !props.data.is_telemed)
    } else {
      props.onButtonAction?.(props.data, action, loading);
    }
  };

  return (
    <Card
      className={`${classes.card}`}
      // onClick={handleClick}
    >
      <CardActionArea onClick={handleSelect}>
        <CardContent>
          <div className="doctor-profile-detail">
            <div>
              <img
                src={props.data.image || IMAGES.doctor}
                onError={(e: any) => (e.target.src = IMAGES.doctor)}
              />
            </div>
            <div>
              <label
                className={classes.title}
                style={{ marginBottom: "0.45rem" }}
              >
                {props.data.full_name}
              </label>
              <label style={styles.ellipsis}>{props.data.specialties}</label>
              <div className={classes.score}>
                <div className="star">
                  <StarRoundedIcon />
                  <label>{props.data.score?.toFixed(1)}</label>
                </div>
                <div className="dot"></div>
                <label>{props.data.review} <FormattedMessage id="bplusClinicKey1250"/></label>
              </div>
            </div>
          </div>

          <div className={classes.description}>
            <div>
              <BoxTitle
                type="label"
                labelStyle={{
                  marginBottom: "0.65rem",
                  color: COLORS.light_grey,
                }}
              >
                <img className="icon" src={IMAGES.pin}></img>
                <label>{props.data.hospital_name}</label>
              </BoxTitle>

              <BoxTitle
                type="label"
                labelStyle={{
                  marginBottom: "0.65rem",
                  color: COLORS.light_grey,
                }}
              >
                <img className="icon" src={IMAGES.follow}></img>
                <label>
                <FormattedMessage id="bplusClinicKey1251"/>:{" "}
                  {props.data.experience
                    ? Number(props.data.experience).toLocaleString("en-US", {
                        style: "decimal",
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                      })
                    : "-"}{" "}
                  <FormattedMessage id="bplusClinicKey590"/>
                </label>
              </BoxTitle>
            </div>
            <div className="price">
              {!!props.data.doctor_fee && props.data.is_telemed && (
                <>
                  <label>฿ {props.data.doctor_fee}</label>
                  <label> <FormattedMessage id="bplusClinicKey1249"/></label>
                </>
              )}
            </div>
          </div>

          <ButtonAppointmentTypes
            // data
            id={props.data.id}
            // config
            separator={true}
            disabledOnline={!props.data.is_telemed}
            disabledOnSite={!props.data.is_onsite}
            // CommonInterface
            loadingStatus={props.loadingStatus}
            // callback
            onButtonAction={handleButtonAction}
          />
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default React.memo(CardDoctorContent);
