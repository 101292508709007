import React, { useState, useMemo } from "react";
// MUI
import makeStyles from "@mui/styles/makeStyles";

// UX
import CardCenterGroupList from "./CardCenterGroupList";
import ItemNotFound from "./ItemNotFound";
// register
import DialogReadMore from "bplus-lib/register/DialogReadMore";
// appointment
import SearchBox from "../appointment/SearchBox";
import { useIntl } from "react-intl";

// Types
type BottomSheetCenterGroupProps = {
  open: boolean;
  items: { id: number; name: string; name_en:string; name_th:string; }[];
  selected?: number;
  isUseMinHight?: boolean;
  // config
  search?: boolean;
  // callback
  onChange: (state: boolean) => any;
  onSelect?: (selected: Record<string, any>) => any;
};

// Images
const IMAGES = {
  notfound: "/images/Appointment/no-information-search.png",
};

// Styles
const COLORS = {
  font: "rgba(57, 57, 57, 1)",
  bg: "rgba(1, 71, 163, 0.05)",
};

const useStyles = makeStyles((theme) => ({
  screen: {
    maxHeight: "87.5vh",
    overflow: "auto",
    margin: "0 -20px",
    padding: "16px",
    color: COLORS.font,
    "&.search": {
      "& div[class*=group_card]": {
        maxHeight: "74.5vh",
        overflow: "auto",
      },
    },"&.user_min_height" : {
      minHeight: "87.5vh",
    }
  },
  search_box: {
    marginBottom: "1rem",
    "& svg": {
      color: "white",
    },
    "& input": {
      background: `${COLORS.bg} !important`,
      border: `none !important`,
    },
    "& .close.circular": {
      right: "9px !important",
    },
  },
}));

const BottomSheetCenterGroup = (props: BottomSheetCenterGroupProps) => {
  const classes = useStyles();
  const intl = useIntl()

  const [textSearch, setTextSearch] = useState<string>("");

  // Memo
  const centerGroupList = useMemo(() => {
    if (!textSearch) {
      return props.items;
    }

    const filterEN = props.items.filter((item) =>
      item.name_en.toLowerCase().includes(textSearch.toLowerCase())
    );
    const filterTH = props.items.filter((item) =>
      item.name_th.toLowerCase().includes(textSearch.toLowerCase())
    );

    return filterEN.length === 0 ? filterTH : filterEN
  }, [textSearch,props.items]);
  // Handler
  const handleSearch = (value: string) => {
    setTextSearch(value);
  };

  const handleChange = (state: boolean) => {
    props.onChange(state);
  };

  return (
    <DialogReadMore
      open={props.open}
      title={intl.formatMessage({id : "bplusClinicKey1238"})}
      centerTitle={true}
      // config
      marginTop={0}
      // callback
      onChange={handleChange}
    >
      <div className={`${classes.screen}${props.search ? " search" : ""}${props.isUseMinHight ? " user_min_height": ""}`}>
        {props.search && (
          <SearchBox
            className={classes.search_box}
            placeholder={intl.formatMessage({id:"bplusClinicKey440"})}
            hideSearchList={false}
            hideButtonFilter={true}
            hideNotFound={true}
            // callback
            onSearch={handleSearch}
          />
        )}

        {textSearch && !centerGroupList.length ? (
          <div style={{ margin: "2rem 0" }}>
            <ItemNotFound
              img={IMAGES.notfound}
              imgSize="6rem"
              subTitle={
                <div>
                  ไม่พบผลการค้นหาสำหรับ <span>“{textSearch}”</span>
                </div>
              }
            />
          </div>
        ) : (
          <CardCenterGroupList
            items={centerGroupList as any[]}
            selected={props.selected}
            // config
            hideShadow={true}
            // callback
            onSelect={props.onSelect}
          />
        )}
      </div>
    </DialogReadMore>
  );
};

export default React.memo(BottomSheetCenterGroup);
